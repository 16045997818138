import React, { useState } from 'react'
import newicon from "../assets/newIcon.svg";
import { BsArrowUpRight } from "react-icons/bs";
import { Simplemodal } from '../components/helpers/modal/simplifiedmodal';
import { RadioOption } from '../components/inputs/radiobutton';
import { Platformbutton } from '../components/helpers/button/button';
import ngnwallet from "../assets/icons/ngnwallet.svg";
import VestiCard from "../assets/images/cards.png";
import flightHero from "../assets/flight/flightbanner.svg";

export const BookAFlight = () => {
      const [showCurrencyModal, setShowCurrencyModal] = useState(false);
      const [option, setOption] = useState("NGN");

      const setPaymentOption = (value) => {
        setOption(value);
      };
  return (
    <div className='w-full px-4 md:px-8 py-7 lg:py-6 lg:px-20 xxl:px-32  vlg:px-64 pt-12'>
    <Simplemodal
        onClick={() => setShowCurrencyModal(false)}
        open={showCurrencyModal}
      >
        <div className="paymentoption-container">
          <div className="paymentoption-inner">
            <div>
              {/* <h2 className="text-vestigreen">{` Step 1 of 2`}</h2> */}
              <h2 className="font-[600] text-[24px]  text-[#14290A]">
                {/* Select Currency */}
                Payment Method
              </h2>
              <p className="font-[300] text-[#2B5219]">
                {/* Select the currency you want to pay with */}
                You will be paying in USD
              </p>
            </div>

            <div className="paymentoption-inner mt-8 center mb-8">
              {/* <RadioOption
                image={ngnwallet}
                changed={setPaymentOption}
                id="1"
                isSelected={option === "NGN"}
                label="Pay in Kenyan Shilling"
                sublabel="Pay for your flight in Kenyan Shilling"
                value="NGN"
              /> */}
              <div className="mb-4"></div>
              <RadioOption
                image={ngnwallet}
                changed={setPaymentOption}
                id="2"
                isSelected={option === "USD"}
                label="Pay in USD"
                value="USD"
                sublabel="Pay for your flight in Dollars"
              />

              <div className="mb-4"></div>
            </div>

            {/* {option === "NGN" && (
              <Platformbutton
                type="normal"
                name="Continue"
                click={() => {
                  window.open(" https://app.wevesti.com/bookflight", "_blank");
                  setShowCurrencyModal(false);
                }}
                classname="fas fa-arrow-right"
              />
            )} */}

            {option === "USD" && (
              <Platformbutton
                type="normal"
                name="Continue"
                click={() => {
                  window.open(
                    " https://app.wevesti.com/bookflight/usd",
                    "_blank"
                  );
                  setShowCurrencyModal(false);
                }}
                classname="fas fa-arrow-right"
              />
            )}
          </div>
        </div>
      </Simplemodal>

    <div className="block md:flex sm:items-center sm:justify-between lg:pl-16 px-[2rem] lg:px-[4rem] p-6 bg-[#004800] rounded-[30px]">
        <div>
        <div className="rounded-[30px] mt-7 bg-[#060E42] w-[60%] sm:w-[50%] md:w-[40%] lg:w-[40%] flex items-center gap-2 p-2 justify-center">
        <p className="text-xs  text-white font-medium  font-fontReg">
            AVAILABLE OFFER
        </p>
        <img src={newicon} alt="" className="w-4 h-4" />
        </div>
        <h1 className="text-white text-4xl w-full lg:w-[60%] pt-6 font-fontSemi xxl:w-[70%]">
        Introducing the new price match offer!.
        </h1>
        <p className="text-base font-extralight text-white pt-6 font-fontReg w-full lg:w-[70%] xxl:w-[80%]">
        If you find a lower flight fare on another platform, we will
        not only match it but also offer an even better price.
        </p>
        <div
        onClick={() => {
            setShowCurrencyModal(true);
        }}
        className="pt-5 pb-8 flex items-center text-[#ffffff] font-fontReg text-xl hover:text-[#67A948] cursor-pointer"
        >
        <>Book a Flight</>
        {/* <Link to="/flight">Book a Flight</Link> */}
        <BsArrowUpRight />

        </div>
        </div>
        <img src={flightHero} alt='' className='md:block mx-auto sm:w-[35%] xlg:w-[25%] w-[62%] flex justify-center text-center items-center'/>
    </div>
  
  </div>
  )
}
