import NigeriaFlag from "../assets/logo-icons/ng-flag.png";
import ZambiaFlag from "../assets/logo-icons/zm-flag.png";
import MexicoFlag from "../assets/logo-icons/mx-flag.png";
import GhanaFlag from "../assets/logo-icons/gh-flag.png";
import UkFlag from "../assets/element/unitedkingdomFlag.png";
import Globe from "../assets/logo-icons/globe.svg";
import UsaFlag from "../assets/element/usa.jpg";
import { useApp } from "../hooks/useApp";
import React from "react";
import { useNavigate } from "react-router-dom";
import KenyanFlag from '../assets/logo-icons/KenyanFlag.png'

const DATA = [
  { title: "GL", img: Globe, currency: "NGN", country: "NIGERIA" },
  { title: "GH", img: GhanaFlag, currency: "GHS", country: "GHANA" },
  { title: "NG", img: NigeriaFlag, currency: "NGN", country: "NIGERIA" },
  { title: "MX", img: MexicoFlag, currency: "NGN", country: "MEXICO" },
  { title: "US", img: UsaFlag, currency: "USD", country: "UNITED STATE" },
  { title: "ZM", img: ZambiaFlag, currency: "ZMW", country: "ZAMBIA" },
  { title: "UK", img: UkFlag, currency: "GBP", country: "UNITED KINGDOM" },
  { title: "KE", img: KenyanFlag, currency: "KES", country: "KENYAN" },
];

// const CountryDropdown = ({ hide, setOpen }) => {
//   const { setCountry } = useApp();
//   const selectCountry = (country) => {
//     setCountry(country);
//     hide();

//   };

const CountryDropdown = ({ hide }) => {
  // const [country, setCountry] = useState("NG");
  const { setCountry } = useApp();
  const navigate = useNavigate()

  // const selectCountry = (country) => {

  //   localStorage.setItem("selectedCountry", JSON.stringify(country)); // Save first
  // setCountry(country); // Then update state

  // if (country.country === "KENYAN") {
  //   navigate("/kenya-home");
  // } else {
  //   localStorage.removeItem("selectedCountry"); // Reset on /
  //   setCountry(null);
  //   navigate("/");
  // }

  // setTimeout(() => hide(), 100);
  // };

  const selectCountry = (country) => {
    setCountry(country);
    localStorage.setItem("selectedCountry", JSON.stringify(country));
  
    // Delay navigation slightly to allow state update
    setTimeout(() => {
      navigate(country.country === "KENYAN" ? "/kenya-home" : "/");
      hide();
    }, 50);
  };
  
  return (
    <div className="">
      <div className="bg-[#F6F8FF] lg:w-[100px] min-h-[15vh] lg:absolute lg:top-[60px] lg:right-0 rounded-sm p-[10px] border-solid border-1 border-headcol">
        <div className="flex flex-col gap-4 text-headcol text-base font-fontLight">
          {DATA.map((country, i) => (
            <div
              key={i}
              onClick={() => selectCountry(country)}
              className="flex flex-row items-center gap-4 cursor-pointer opacity-80 hover:opacity-100 hover:font-fontBold"
            >
              <img
                className="w-5 h-5 object-cover rounded-sm"
                src={country.img}
                alt="ng-flag"
              />
              <p className="">{country.title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CountryDropdown;

// export const SelectCountryDropdown = ({ hide }) => {
//   // const [country, setCountry] = useState("NG");
//   const { setCountry } = useApp();

//   const selectCountry = (country) => {
//     setCountry(country);
//     localStorage.setItem("selectedCountry", country.currency); // Save selected country to local storage
//     hide();
//   };
//   // console.log(country);
//   return (
//     <div className="">
//       <div className="bg-[#F6F8FF] lg:w-[100px] min-h-[15vh] lg:absolute lg:top-[60px] lg:right-0 rounded-sm p-[10px] border-solid border-1 border-headcol">
//         <div className="flex flex-col gap-4 text-headcol text-base font-fontLight">
//           {DATA.map((country, i) => (
//             <div
//               key={i}
//               onClick={() => selectCountry(country)}
//               className="flex flex-row items-center gap-4 cursor-pointer opacity-80 hover:opacity-100 hover:font-fontBold"
//             >
//               <img
//                 className="w-5 h-5 object-cover rounded-sm"
//                 src={country.img}
//                 alt="ng-flag"
//               />
//               <p className="">{country.country}</p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };
