import VestiLogo from "../assets/logo-icons/vesti-logo.png";
import GlobeIcon from "../assets/logo-icons/globe.svg";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";
import { TfiClose } from "react-icons/tfi";
import { Link, NavLink } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import { useState, useEffect } from "react";
import NavLinks from "../components/NavLinks";
import MobileNav from "../components/MobileNav";
import CountryDropdown from "../components/CountryDropdown";
import { useApp } from "../hooks/useApp";
import plane from "../assets/flight/flight 1.png";
import { useNavigate } from "react-router-dom";
import KenyanFlag from "../assets/logo-icons/KenyanFlag.png";

const NavBar = () => {
  const [open, setOpen] = useState(false);
  const { country, setCountry } = useApp();

  const [showCountry, setShowCountry] = useState(false);

    // useEffect(() => {
    //   const savedCountry = localStorage.getItem("selectedCountry");
    //   if (savedCountry) {
    //     try {
    //       setCountry(JSON.parse(savedCountry));
    //     } catch (error) {
    //       console.error("Error parsing country from localStorage:", error);
    //       setCountry({ img: GlobeIcon, title: "GL" }); // Set a default country object
    //     }
    //   } else {
    //     setCountry({ img: GlobeIcon, title: "GL" }); // Default if nothing is in localStorage
    //   }
    // }, []);

    useEffect(() => {
      const savedCountry = localStorage.getItem("selectedCountry");
      if (savedCountry) {
        try {
          setCountry(JSON.parse(savedCountry));
        } catch (error) {
          console.error("Error parsing country from localStorage:", error);
          setCountry({ img: GlobeIcon, title: "GL" }); // Set default country
        }
      } else {
        setCountry({ img: GlobeIcon, title: "GL" }); // Default country
      }
    }, []);
    
  const toggleCountry = () => {
    setShowCountry(!showCountry);
  };
  // const navigate = useNavigate();
  // const [open, setOpen] = useState(false);
  // const { country, setCountry } = useApp();

  // const [showCountry, setShowCountry] = useState(false);

  // useEffect(() => {
  //   const savedCountry = localStorage.getItem("selectedCountry");
  //   if (savedCountry) {
  //     try {
  //       setCountry(JSON.parse(savedCountry));
  //     } catch (error) {
  //       console.error("Error parsing country from localStorage:", error);
  //       setCountry(
  //         JSON.parse({
  //           title: "KE",
  //           img: KenyanFlag,
  //           currency: "KES",
  //           country: "KENYAN",
  //         })
  //       );
  //     }
  //   } else {
  //     setCountry({
  //       title: "KE",
  //       img: KenyanFlag,
  //       currency: "KES",
  //       country: "KENYAN",
  //     });
  //   }
  // }, []);

  // console.log(country);

  // const toggleCountry = () => {
  //   setShowCountry(!showCountry);
  // };

  return (
    <>
      <nav className="bg-[#060E42] w-full  py-7 lg:py-6 bg:px-20 xxl:px-32  vlg:px-64 fixed top-0 left-0 right-0 z-50 text-white font-fontReg">
        <div className="px-4 md:px-8">
          <div className="vlg:container flex vlg:mx-auto justify-between items-center">
            <div className="w-[70px] bg:w-[100px]">
              <Link to="/">
                <img src={VestiLogo} alt="Vesti-Logo" />
              </Link>
            </div>
            <NavLinks
              addStyle="hidden bg:flex items-center flex-col bg:flex-row "
              open={open}
              setOpen={setOpen}
            />
            <div className="hidden bg:flex items-center bg:gap-3">
              {/* NavLinks */}

              <div className="">
                <ul className="flex mt-4 items-center text-[14px] font-fontReg bg:gap-10 ">
                  <li>
                    <Link
                      onClick={() =>
                        window.open("https://app.wevesti.com/auth", "_blank")
                      }
                      // onClick={() =>
                      //   window.open("http://localhost:3000/auth", "_blank")
                      // }
                      className="hover:text-vestigreen hover:font-fontSemi"
                    >
                      Sign In
                    </Link>
                  </li>
                  <li>
                    <div className="">
                      <Link
                        onClick={() =>
                          window.open(
                            "https://app.wevesti.com/register",
                            "_blank"
                          )
                        }
                      >
                        <Button className="normal-case bg:w-[100%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26] py-2 px-4 text-[14px]">
                          Create an account
                        </Button>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div
                      className="flex items-center gap-2 relative"
                      onClick={toggleCountry}
                    >
                      <img
                        className="w-[30px] h-[30px] object-cover  rounded-full "
                        // src={`${!country.img ? GlobeIcon : country.img}`}
                        src={country?.img ? country.img : GlobeIcon}
                        alt="globe-icon"
                      />
                      {showCountry ? <IoIosArrowUp /> : <IoIosArrowDown />}
                      {showCountry ? (
                        <CountryDropdown hide={() => setShowCountry(false)} />
                      ) : null}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex gap-4 items-end bg:hidden cursor-pointer text-3xl font-fontBold">
              <NavLink to="/flight">
                <img src={plane} alt="" />
              </NavLink>
              <div onClick={() => setOpen(!open)}>
                {open ? <TfiClose /> : <GiHamburgerMenu />}
              </div>
            </div>
          </div>
        </div>

        {/* Mobile nav */}
        <div
          className={`bg:hidden relative mt-10 transition delay-150 duration-300 ease-linear ${
            open ? "visible" : "hidden"
          }`}
        >
          <div className="">
            <MobileNav open={open} setOpen={setOpen} />
          </div>
        </div>
        {/* End of the Mobile Nav */}
      </nav>
    </>
  );
};

export default NavBar;
