import React from 'react'
import NavBar from '../../containers/NavBar'
import { SuccessStoryHero } from './SuccessStoryHero'
import { PrefferedTopic } from './PrefferedTopic'

export const SuccessStory = () => {
  return (
    <div>
        <NavBar/>
        <SuccessStoryHero/>
        <PrefferedTopic/>
    </div>
  )
}
