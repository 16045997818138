// import FeaturedLogos from "../assets/logo-icons/feat-logos.png";
// import GlobalPageImage from "../assets/images/gpphone.png";
import React, { useState } from "react";
import { Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import Techstars from "../assets/logo-icons/techstars1.svg";
import Techcity from "../assets/logo-icons/techcity1.svg";
import Bloomberg from "../assets/logo-icons/bloomberg1.svg";
import { openLink } from "../helpers/helpers";
import heroGif from "../assets/miai/phoneAipage.gif";
import { useNavigate } from "react-router-dom";
import { RxDotFilled } from "react-icons/rx";
import popUpIcon from "../assets/popUpIcon/H1bpopup.svg";
import LocationInput from "./AiLocation";
import axios from "../components/api/axios";
import miaiMockup from "../assets/miai/miaiMockup.svg";

// import { MdArrowOutward } from "react-icons/md";
// import { Simplemodal } from "../components/helpers/modal/simplifiedmodal";
// import { NewsData } from "./newdata";
// import { MdCalendarMonth } from "react-icons/md";

const Showcase = (props) => {
  const navigate = useNavigate();
  const [userCountry, setUserCountry] = useState("");
  const [newLocation, setNewLocation] = useState("");

  const handleClick = () => {
    navigate("/h1b-lottery-payment");
  };

  const Handlepost = async () => {
    props.setHeight("100vh");
    props.setLoading(true);
    props.setTypewriterMsg("Typing");
    props.addMessage(
      `Pathway Visa Guide for moving from ${userCountry} to ${newLocation}`,
      true
    );

    const payload = {
      question: `Pathway Visa Guide for moving from ${userCountry} to ${newLocation}`,
      chatId: "67c884b0-0f4f-4475-8853-4c5e3512b9fc",
    };

    props.setInputText("");
    await axios
      .post("/v1/bot/chat-no-auth-gemini", payload)
      .then((response) => {
        props.setAnswer(
          response.data?.data?.data?.candidates[0]?.content?.parts[0]?.text
        );
        props.setLoading(false);

        // console.log(typeof response.data?.data?.data?.answer);

        setTimeout(() => {
          // const res = props.generateChatbotResponse(
          //   response.data?.data?.data?.answer
          // );
          props.addMessage(
            response.data?.data?.data?.candidates[0]?.content?.parts[0]?.text,
            false
          );
          // props.addMessage(res, "Formatted");
        }, 20);

        props.setTypewriterMsg("Typing");
      })
      .catch((error) => {
        // console.log(error);
        props.setInputText("");
      });
  };
  return (
    <div className="bg-[#060E42] relative w-full h-fit bg:h-[100svh] xxxl:h-[85svh] overflow-y-hidden">
      <img
        src={miaiMockup}
        alt=""
        className="bg:absolute bg:block z-10 top-[8rem] right-0 hidden w-[27%] bottom-0"
      />

      <div>
        <div className="newMiai-animatedBg z-10 hidden md:block"></div>
        <div className="lightBg bg-vestigreen blur-lg opacity-[0.275] md:hidden z-10"></div>
      </div>

      {/* <div className="neww "></div>
      <div className="lightBg xsm:hidden"></div> */}

      <div className="  w-full   px-4 md:px-8 pt-12 lg:pt-20 lg:px-20 xxl:px-32  vlg:px-64  text-white font-fontReg">
        <div className="vlg:container z-10 vlg:mx-auto ">
          {/* <Link to="/news-room" className="z-[999]"> */}
          <p className="pt-12 relative z-10 lg:pt-6 text-center text-[14px] cursor-pointer hover:text-vestigreen">
            See Announcements 🎉
          </p>
          {/* </Link> */}

          <div
            onClick={() => {
              handleClick();
            }}
            className="blur-bg  w-[100%] mt-12 mb-[-3rem]  xlg:hidden cursor-pointer xlg:w-fit py-4"
          >
            <div className="text-[12px] text-vestigreen flex flex-col gap-2 justify-center items-center ">
              <div className="flex items-center gap-2">
                <img src={popUpIcon} alt="" className="h-6 w-6" />
                <p>Regular H1B Registration with Vesti for 2025 is now open</p>
              </div>

              <p
                onClick={() => {
                  handleClick();
                }}
                className=" cursor-pointer"
              >
                Click here to apply
              </p>
            </div>
          </div>

          <div className="grid bg:grid-cols-2 gap-20 z-10 lg:gap-0 xlg:gap-8">
            <div className="col-span-1 pt-6 sm:pt-20 ">
              <div
                onClick={() => {
                  handleClick();
                }}
                className="blur-bg w-[100%]  hidden xlg:block cursor-pointer xlg:w-fit py-4"
              >
                <div className="text-[13px]    text-vestigreen flex gap-2 justify-center items-center ">
                  {/* <div className="flex items-center gap-2">
                              <p className="w-fit"> Move to the US </p>{" "}
                              <img src={popUpIcon} alt="" className="h-6 w-6" />{" "}
                              <p>as an H-1B Worker with minimum HND/Bachelors Degree </p>
                            </div> */}

                  {/* <RxDotFilled className="text-white text-[9px]" /> */}

                  <p>
                    Regular H1B Registration with Vesti for 2025 is now open
                  </p>
                  <RxDotFilled className="text-white text-[9px]" />

                  <p
                    onClick={() => {
                      handleClick();
                    }}
                    className=" cursor-pointer"
                  >
                    Click here to apply
                  </p>
                </div>
              </div>
              <h1 className="text-[30px] z-10 relative font-fontSemi lg:text-5xl bg:text-[32px] xl:text-[32px] pb-5  xlg:w-[80%] lg:font-fontBold !leading-[50px] lg:!leading-[60px] xlg:!leading-[50px]">
                {/* The */}
                <span className="text-white z-10">
                  Global Immigrants Platform
                </span>{" "}
                {/* <br className="hidden sm:block" /> */}
                <span className="text-[#67A948] z-10"> Powered by AI </span>
              </h1>
              <p className="text-lg pb-2 lg:w-[80%] z-10 lg:text-[20px] bg:text-[15px] font-sansec  relative">
                Millions of people moving to new countries use Vesti for
                accessing financial services like bank accounts, debit and
                credit cards.
              </p>
              <div className="relative z-10">
                <LocationInput
                  height={props.height}
                  setHeight={props.setHeight}
                  smallheight={props.smallheight}
                  setSmallHeight={props.setSmallHeight}
                  Handlepost={Handlepost}
                  userCountry={userCountry}
                  setUserCountry={setUserCountry}
                  newLocation={newLocation}
                  setNewLocation={setNewLocation}
                />
              </div>
              {/* onClick=
            {() => {
              props.setHeight("100vh");
            }} */}
              {/* <Link
              to="https://app.wevesti.com/auth"
              window.open("https://app.wevesti.com/auth", "_blank")

              onClick={() => props.setHeight("100vh")}
            >
              <Button className="font-fontLight text-base capitalize p-4  mb-12 w-[100%] sm:w-[60%] lg:w-[80%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26]">
                Get started in minutes
              </Button>
            </Link> */}
              {/* <img src={FeaturedLogos} alt="featured" /> */}
              <div className=" flex flex-wrap mt-8  lg:mt-0 lg:mb-10  items-center opacity-80 gap-12 xl:gap-10">
                <img
                  className="cursor-pointer"
                  src={Techstars}
                  alt="techstars"
                  onClick={() =>
                    openLink(
                      "https://www.techstars.com/newsroom/techstars-nyc-class-of-spring-2022"
                    )
                  }
                />
                <img
                  className="cursor-pointer"
                  src={Techcity}
                  alt="techcity"
                  onClick={() =>
                    openLink(
                      "https://www.techcityng.com/migrants-banking-app-vesti-raises-500k-in-pre-seed-funding/"
                    )
                  }
                />
                <img
                  className="xxl:flex justify-center cursor-pointer"
                  src={Bloomberg}
                  alt="bloomberg"
                  onClick={() =>
                    openLink(
                      "https://www.bloomberg.com/press-releases/2022-08-30/nova-credit-and-vesti-partner-to-alleviate-financial-barriers-for-u-s-bound-nigerians"
                    )
                  }
                />
              </div>
            </div>

            <div className="col-span-1 relative z-10 bg:h-[100svh]">
              <img
                className="w-full bg:absolute mb-[-12rem] bg:mb-0 object-contain z-10 xlg:h-full xlg:w-[90%] xxl:h-auto bottom-[12rem]  bg:bottom-[-9.5rem] xxxl:bottom-[3rem] right-[4rem] xxxl:right-[-8rem]"
                src={heroGif}
                alt="globalpage-phone"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Showcase;
