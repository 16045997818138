import { Button } from '@material-tailwind/react'
import React from 'react'
import opeImg from '../../assets/SuccessStory/opeImg.png'

export const PrefferedTopic = () => {
  return (
    <div className='px-[18px] sm:px-[30px] md:px-[80px]'>
        <div className="border bg-[#060E42] pt-2 sm:pt-4 md:pt-16 rounded-md">
        <div className='md:px-[6%] px-[6%] sm:px-[8%] md:mt-0 mt-10 md:flex md:items-center lg:gap-[10%] gap-[6%]'>
            <div className=''>
            <h1 className="text-[27px] text-[#ffffff] sm:text-[28px] md:text-[27px] xl:text-[30px] md:mt-0 mt-2 font-bold">
            Select Your Preferred Topic 
            </h1>
            <p className="text-[#F8FEF5] xxl:text-[20px] sm:text-[18px] text-[14px] leading-loose mt-6 tracking-wider">
            Choose the "Financial Stories" button for content focused on finance, such as investment or economic trends. 
            Select the "Migration Stories" button for content about migration
            </p>
            {/* <div className='mt-8'></div> */}
            <div className="sm:flex sm:flex-row sm:justify-between sm:gap-4 mt-[4%] sm:mt-[7%]">
                    <Button
                        className="font-fontLight text-[#ffffff] text-base capitalize p-5 mb-4 sm:mb-10 md:mb-16 w-[100%] sm:w-[60%] lg:w-[50%] border border-vestigreen bg-transparent transition duration-700 ease-in-out"
                    >
                        Financial Stories
                    </Button>
                    <Button
                        className="font-fontLight text-[#ffffff] text-base capitalize p-5 mb-10 md:mb-16 w-[100%] sm:w-[60%] lg:w-[50%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26]"
                    >
                        Migration Stories
                    </Button>
                </div>
            </div>
            <div>
                <img
                // className="vlg:w-[80rem] xlg:w-[40rem] lg:w-[30rem] md:w-[22rem] w-[100%] rounded-md"
                className="w-[80rem] rounded-md mb-[3.5rem] md:mb-[4.3rem]"
                src={opeImg}
                alt=""
                />
            </div>
           
        </div>
        </div>
    </div>
  )
}
