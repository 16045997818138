import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import plane from "../assets/miai/charter-flight.svg";
const UserLocation = (props) => {
  const [error, setError] = useState(false);

  const handleLocationChange = (event) => {
    props.setNewLocation(event.target.value);
  };

  const GOOGLE_API_KEY = "AIzaSyBEO0G6kLtVJEznlncUow-p0imTlHJ8fn0"; // Replace with your actual API key

  const [location, setLocation] = useState({
    lat: null,
    lng: null,
    address: null,
  });

  // Function to get user's latitude and longitude
  const fetchLocationFromGoogleAPI = async () => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/geolocation/v1/geolocate?key=${GOOGLE_API_KEY}`,
        {
          method: "POST",
        }
      );

      const data = await response.json();
      if (data.location) {
        const { lat, lng } = data.location;
        setLocation((prev) => ({ ...prev, lat, lng }));

        // Fetch formatted address as City, Country
        fetchAddressFromCoordinates(lat, lng);
      } else {
        console.error("Failed to get location:", data);
      }
    } catch (error) {
      console.error("Error fetching location:", error);
    }
  };

  // Function to get "City, Country" format from coordinates
  const fetchAddressFromCoordinates = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_API_KEY}`
      );

      const data = await response.json();
      if (data.results.length > 0) {
        const addressComponents = data.results[0].address_components;

        let city = "";
        let country = "";

        addressComponents.forEach((component) => {
          if (component.types.includes("locality")) {
            city = component.long_name; // City
          }
          if (component.types.includes("country")) {
            country = component.long_name; // Country
          }
        });

        const formattedAddress =
          city && country ? `${city}, ${country}` : "Location not found";
        setLocation((prev) => ({ ...prev, address: formattedAddress }));
        props.setUserCountry(formattedAddress);
      } else {
        console.error("Failed to get address:", data);
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  // Fetch location when component mounts
  useEffect(() => {
    fetchLocationFromGoogleAPI();
  }, []);

  // console.log(location);

  //    height={props.height}
  //         setHeight={props.setHeight}
  //         smallheight={props.smallheight}
  //         setSmallHeight={props.setSmallHeight}

  return (
    <div className="w-full bg:w-[80%] mt-6">
      <p className="text-white mb-4 text-[16px] bg:text-[18px]">
        Enter your desired destination below to get started{" "}
      </p>
      <div className="flex gap-2 bg:gap-3 items-center w-full">
        <div className="w-full relative">
          {/* Pre-filled input field with userCountry */}
          <label className="text-[11px]  text-white absolute top-[-10px] rounded-[5px] left-4 bg-[#071E32] px-2">
            Origin
          </label>
          <input
            type="text"
            placeholder="Lagos, Nigeria"
            value={props.userCountry}
            onChange={(e) => props.setUserCountry(e.target.value)} // Optional: allow editing userCountry
            disabled={false} // Disable editing if not required
            className="text-[#060E42] text-[13px] px-2 py-3 rounded-md outline-none"
          />
        </div>
        <img src={plane} alt="" className=" rotate-animation" />

        <div className="w-full relative">
          {/* Second input field for the user to enter a new location */}
          <label className="text-[11px]  text-white absolute top-[-10px] rounded-[5px] left-4 bg-[#071E32] px-2">
            Destination
          </label>
          <input
            type="text"
            placeholder="Texas, US"
            value={props.newLocation}
            onChange={handleLocationChange}
            className="text-[#060E42] text-[13px] px-2 py-3 rounded-md outline-none"
          />
        </div>
      </div>

      {error && !props.newLocation ? (
        <p className="text-red-700 w-full mb-1  text-[12px] text-center font-semibold">
          *Enter Desired Destination to proceed
        </p>
      ) : (
        <></>
      )}

      {error && !props.userCountry ? (
        <p className="text-red-700 w-full mb-1  text-[12px] text-center font-semibold">
          *Enter Origin to proceed
        </p>
      ) : (
        <></>
      )}
      {props.newLocation && props.userCountry ? (
        <div
          onClick={() => {
            props.Handlepost();
          }}
          className="font-[600] cursor-pointer text-sm capitalize p-4 mt-1 rounded-md text-center mb-12 w-[100%] lg:w-[100%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26]"
        >
          Discover Your Pathway with Miai ™
        </div>
      ) : (
        <div
          onClick={() => {
            setError(true);
          }}
          className="font-[600] cursor-pointer text-sm capitalize p-4 mt-1 rounded-md text-center mb-12 w-[100%] lg:w-[100%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26]"
        >
          Discover Your Pathway with Miai ™
        </div>
      )}

      {/* </Link> */}
    </div>
  );
};

export default UserLocation;
